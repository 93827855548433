import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';

//Import Config Provider

const Home = lazy(() => import('pages/Home'));
const PIX = lazy(() => import('pages/PIX'));
// const Config = lazy(() => import('pages/Config'));
// const Checkout = lazy(() => import('pages/Checkout'));

const AppRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/gerarpix',
            element: <PIX />
        }
    ]
}

export default AppRoutes;