import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { api } from "services/api";

//Data
// import numerosData from 'data/numeros.json';

interface Numeros {
  id: number;
  valor: number;
  active: boolean;
  selected: boolean;
}

interface Premios {
  id: number;
  campanha: number;
  nome: string;
  lugar: string;
  descricao: string;
  image_url: string;
  created_at: string;
  updated_at: string;
}

interface Campanha {
  id: number;
  nome: string;
  descricao: string;
  valor: number;
  qtd_numeros: number;
  sorteio_dt: string;
  whatsapp: string;
  ativo: number;
  created_at: string;
  updated_at: string;
}

interface Bilhetes {
  id: number;
  nome: string;
  telefone: string;
}

interface Message {
  status: string;
  message: string;
}

interface DataContextProps {
  campanha: Campanha | null;
  setCampanha: Dispatch<SetStateAction<Campanha | null>>;
  premios: Premios[];
  setPremios: Dispatch<SetStateAction<Premios[]>>;
  numbers: Numeros[];
  setNumbers: Dispatch<SetStateAction<Numeros[]>>;
  checkoutOpen: boolean;
  setCheckoutOpen: Dispatch<SetStateAction<boolean>>;
  getNumeros: () => void;
  saveBilhetes: (bilhetes: Bilhetes[]) => void;
  messageSave: Message | null;
}

export const DataContext = createContext<DataContextProps>({
  campanha: null,
  setCampanha: () => {},
  premios: [],
  setPremios: () => {},
  numbers: [],
  setNumbers: () => {},
  checkoutOpen: false,
  setCheckoutOpen: () => {},
  getNumeros: () => {},
  saveBilhetes: () => {},
  messageSave: null,
});

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider = ({ children }: DataProviderProps) => {
  const [campanha, setCampanha] = useState<Campanha | null>(null);
  const [premios, setPremios] = useState<Premios[]>([]);
  const [numbers, setNumbers] = useState<Numeros[]>([]);
  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const [messageSave, setMessageSave] = useState<Message | null>(null);

  const getCampanha = async (): Promise<void> => {
    try {
      const response = await api.get("/campanhas");
      const data = response.data;
      setCampanha(data.campanha);
      setPremios(data.premios);
    //   console.log(data.campanha.descricao);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getNumeros = async (): Promise<void> => {
    try {
      const response = await api.get("/bilhetes");
      const data = response.data;
      setNumbers(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    void getCampanha();
    void getNumeros();
  }, []);

  const saveBilhetes = async (bilhetes: Bilhetes[]): Promise<void> => {
    try {
      setMessageSave(null);
      const response = await api.post("/bilhetes", bilhetes)
      const data = response.data;
      setMessageSave(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const dataContextValue = {
    campanha,
    setCampanha,
    premios,
    setPremios,
    numbers,
    setNumbers,
    checkoutOpen,
    setCheckoutOpen,
    getNumeros,
    saveBilhetes,
    messageSave,
  };

  return (
    <DataContext.Provider value={dataContextValue}>
      {children}
    </DataContext.Provider>
  );
};
