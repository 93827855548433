import { useState } from 'react'
import { Outlet } from 'react-router-dom';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from '../getLPTheme';

const LoginLayout = () => {
  const [mode, setMode] = useState<PaletteMode>('dark');
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Outlet />
      </Box>
    </ThemeProvider>
  )
}

export default LoginLayout
