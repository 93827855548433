import { lazy } from 'react';

import AdminLayout from 'layout/AdminLayout';

//Import Config Provider

const Admin = lazy(() => import('pages/Admin'));
// const Config = lazy(() => import('pages/Config'));
// const Checkout = lazy(() => import('pages/Checkout'));

const AdminRoutes = {
    path: '/',
    element: <AdminLayout />,
    children: [
        {
            path: '/admin',
            element: <Admin />
        }
    ]
}

export default AdminRoutes;