import { lazy } from 'react';

import LoginLayout from 'layout/LoginLayout';

const Login = lazy(() => import('pages/Login'));

const LoginRoutes = {
    path: '/',
    element: <LoginLayout />,
    children: [
        {
            path: '/login',
            element: <Login />
        }
    ]
}

export default LoginRoutes;