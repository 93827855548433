import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Buffer } from 'buffer';
import { DataProvider } from 'contexts/DataContext';

window.Buffer = window.Buffer ?? Buffer

//Context

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <DataProvider>
      <BrowserRouter basename='/'>
        <App />
      </BrowserRouter>
    </DataProvider>
  </React.StrictMode>
);
