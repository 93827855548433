import { lazy } from 'react';

import DashboardLayout from 'layout/DashboardLayout';
import Dashboard from 'pages/Dashboard';

//Import Config Provider

const DashboardPage = lazy(() => import('pages/Dashboard'));
// const Config = lazy(() => import('pages/Config'));
// const Checkout = lazy(() => import('pages/Checkout'));

const DashboardRoutes = {
    path: '/',
    element: <DashboardLayout />,
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage />
        }
    ]
}

export default DashboardRoutes;